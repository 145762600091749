import { getCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import ConvertReporter from '../common/ConvertReporter';

const PREFIX = '_nhft_';

export enum Experiment {
    TEST_IMAGE_SLIDER_HOUSE_OVERVIEW_PAGE = 'TEST_IMAGE_SLIDER_HOUSE_OVERVIEW_PAGE'
}

export enum DeploymentEnvironment {
    DEV = 'dev',
    PROD = 'prod'
}

type ExperimentType = {
    experimentId: number;
    originalId: number;
    variants: number[];
};

export const EXPERIMENTS: Record<string, Record<string, ExperimentType>> = {
    [Experiment.TEST_IMAGE_SLIDER_HOUSE_OVERVIEW_PAGE]: {
        [DeploymentEnvironment.DEV]: {
            experimentId: 1004130576,
            originalId: 1004309358,
            variants: [1004309359]
        },
        [DeploymentEnvironment.PROD]: {
            experimentId: 1004130561,
            originalId: 1004309318,
            variants: [1004309319]
        }
    }
};

export enum FeatureToggle {
    TRANSLATIONS = 'translations',
    OPEN_GDS_ONBOARDING = 'open-gds-onboarding',
    NEW_CALENDAR = 'new-calendar',
    SEARCH_AVAILABILITY_INDEX = 'search-availability-index'
}

export function isFeatureEnabled(name: string): boolean {
    const cookieValue = getCookieValue(PREFIX + name);
    return cookieValue === 'true';
}

export function isVariantAB(name: Experiment, variantIndex: number): boolean {
    const experiment: ExperimentType | undefined = getExperimentData(name);

    if (experiment === undefined) {
        return false;
    }

    try {
        const cookieValue = ConvertReporter.decodeCookie();
        const entries = Object.entries(cookieValue) || [];

        if (entries.length === 0) {
            return false;
        }

        const matchingExperiment = entries.find(
            ([entry]) => entry === experiment.experimentId.toString()
        );

        if (!matchingExperiment) {
            return false;
        }

        return matchingExperiment[1].v === experiment.variants[variantIndex];
    } catch (error) {
        return false;
    }
}

function getExperimentData(name: Experiment): ExperimentType | undefined {
    if (!EXPERIMENTS[name]) {
        throw new Error(`Experiment does not exist: ${name}`);
    }

    if (document.documentElement.dataset.deploymentEnv === DeploymentEnvironment.PROD) {
        return EXPERIMENTS[name][DeploymentEnvironment.PROD];
    }

    return EXPERIMENTS[name][DeploymentEnvironment.DEV];
}
